import { BannerType, Languages } from '@/constants'
import { useLocalStorage } from '@/hooks'
import { useCookie } from '@/hooks/useCookie'
import { useCookie1Y } from '@/hooks/useCookie1Y'
import {
  type BannerList,
  type Keywords,
  type Menu,
  type ProductCompare,
  type ProductViewed,
  type Province,
  type SystemConfig,
} from '@/types'
import constate from 'constate'
import { useEffect, useMemo, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
type Props = {
  stores: Province[] | null
  area: string | null
  regions: Province[] | null
  language: Languages
  systemConfig: SystemConfig
  menus: Menu[] | null
  quickMenu: Menu[] | null
  bottomMenu: Menu[] | null
  banner: BannerList[] | null
  keywords: Keywords[] | null
}

const defaultSaleRegion = process.env.NEXT_PUBLIC_SALE_REGION_DEFAULT

const StoreContainer = (props: Props) => {
  const [isOpenPopupLocation, setIsOpenPopupLocation] = useState(false)
  const [isDisplayAreaPopup, setIsDisplayAreaPopup] = useLocalStorage<boolean>(
    'areaPopup',
    true
  )
  const [productCompares, setProductCompares] = useLocalStorageState<
    ProductCompare[]
  >('productCompare', { defaultValue: [{}, {}, {}] })

  const [productViewed, setProductViewed] = useLocalStorage<ProductViewed[]>(
    'productViewed',
    []
  )
  const [isOpenComparePopup, setIsOpenComparePopup] = useState(false)
  const [isOpenAddComparePopup, setIsOpenAddComparePopup] = useState(false)
  const [isOpenComingSoon, setIsOpenComingSoon] = useState(false)
  const [catViewId, setCatViewId] = useState<string | null>(null)
  const [stores, setStores] = useState<Province[]>([])
  const [regions, setRegions] = useState<Province[]>([])
  const [megaMenu, setMegaMenu] = useState<Menu[]>(props.menus || [])
  const [quickMenu, setQuickMenu] = useState<Menu[]>(props.quickMenu || [])
  const [bottomMenu] = useState<Menu[]>(props.bottomMenu || [])
  const [banner, setBanner] = useState<BannerList[]>(props.banner || [])
  const [province, setProvince] = useCookie<string | null>('province', null)
  const [destinationCode, setDestinationCode] = useCookie<string | null>(
    'destinationCode',
    null
  )
  const [isPreOrder, setIsPreOrder] = useCookie<string | null>(
    'isPreOrder',
    null
  )
  const [keywords, setKeywords] = useState<Keywords[]>(props.keywords || [])
  const [selectedLang, setSelectedLang] = useCookie<
    Languages | undefined | null
  >('language', props.language || Languages.VN)

  const [area, setArea] = useCookie1Y<string | null>('area', null)
  const [areaCode, setAreaCode] = useCookie1Y<string | null>('area-code', null)
  useEffect(() => {
    const checkShowArea = localStorage.getItem('area-code')
    if (checkShowArea != 'true') setIsOpenPopupLocation(true)
  }, [])

  useEffect(() => {
    if (regions) {
      const defaultStore = regions.find(e => e?.code === defaultSaleRegion)
      if (defaultStore && defaultSaleRegion && !areaCode) {
        setAreaCode(defaultStore?.code)
      }
    }
  }, [regions])
  useEffect(() => {
    if (props.regions) {
      setRegions(props.regions)
    }
  }, [props.regions])

  useEffect(() => {
    if (props.menus) {
      setMegaMenu(props.menus)
    }
  }, [props.menus])

  useEffect(() => {
    if (props.quickMenu && !quickMenu) {
      setQuickMenu(props.quickMenu)
    }
  }, [props.quickMenu])
  useEffect(() => {
    if (props.keywords && keywords.length == 0) {
      setKeywords(props.keywords)
    }
  }, [props.keywords])

  useEffect(() => {
    if (props.banner) {
      setBanner(props.banner)
    }
  }, [props.banner])

  useEffect(() => {
    if (props.stores) {
      setStores(props.stores)
    }
  }, [props.stores])
  useEffect(() => {
    if (props.regions) {
      setRegions(props.regions)
    }
  }, [props.regions])
  useEffect(() => {
    if (props.language && !selectedLang) {
      setSelectedLang(props.language)
    }
  }, [props.language])

  const topBanner = useMemo(() => {
    const topBanner = banner.find(e => e.type === BannerType.TOP_BAR)
    return topBanner
  }, [banner])

  const sideBarDataBanner = useMemo(() => {
    const sideBarDataBanner = banner.find(e => e.type === BannerType.SIDEBAR)
    return sideBarDataBanner ? sideBarDataBanner.items : []
  }, [banner])

  return {
    province,
    setProvince,
    area,
    setArea,
    setSelectedLang,
    setDestinationCode,
    destinationCode,
    selectedLang,
    stores,
    banner,
    megaMenu,
    quickMenu,
    topBanner,
    sideBarDataBanner,
    keywords,
    setStores,
    isOpenPopupLocation,
    setIsOpenPopupLocation,
    isDisplayAreaPopup,
    bottomMenu,
    setIsDisplayAreaPopup,
    isOpenComingSoon,
    setIsOpenComingSoon,
    productCompares,
    setProductCompares,
    isOpenComparePopup,
    setIsOpenComparePopup,
    isOpenAddComparePopup,
    setIsOpenAddComparePopup,
    productViewed,
    setProductViewed,
    catViewId,
    setCatViewId,
    isPreOrder,
    setIsPreOrder,
    areaCode,
    setAreaCode,
    regions,
    setRegions,
  }
}

export const [StoreProvider, useStore] = constate(StoreContainer)
