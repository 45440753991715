/* eslint-disable @typescript-eslint/no-explicit-any */
import { Languages, LocalStorageKeys, Platform, SaleChannel } from '@/constants'
import type {
  InternalAxiosRequestConfig as AxiosConfig,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { v4 as uuidv4 } from 'uuid'

let context = <Record<string, string>>{}
interface AxiosMetadaInterface extends AxiosRequestConfig {
  metadata: any
}

type ContextOverride = { language?: string | null }
export const setContext = async (_context: any, override?: ContextOverride) => {
  context = _context
  if (override && override.language) {
    if (context.language) {
      override.language = context.language
    } else {
      context = { ...context, ...{ language: override.language } }
    }
  }

  context.messages = (
    await import(`../../messages/${context.language || Languages.VN}.json`)
  ).default
  return context
}
export const getTranslate = (
  data?: Record<string, string> | Record<string, string[]> | any,
  lang?: Languages | null
) => {
  if (!data) return ''
  if (lang) {
    return data[lang]
  }
  const browserLang = getCookie('language')
  if (!isServer && browserLang) {
    if (data[browserLang as string]) {
      return data[browserLang as string]
    }
    return data[Languages.VN]
  }
  if (data[context.language]) {
    return data[context.language]
  }
  return data[Languages.VN]
}

export const isServer = typeof window === 'undefined'
export const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT !== 'dev'
export const DEFAULT_REGION = process?.env?.NEXT_PUBLIC_SALE_REGION_DEFAULT
function onSuccessfulRequest(config: AxiosConfig<any>): AxiosConfig<any> {
  const isNewVersion =
    getCookie(LocalStorageKeys.VERSION) !==
    process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION
  if (isNewVersion) {
    deleteCookie('province')
    deleteCookie('area-code')
    deleteCookie('token')
    deleteCookie('guestId')
    deleteCookie('channel')
    deleteCookie('access')
    setCookie('access', process.env.NEXT_PUBLIC_ACCESS_TOKEN, {
      maxAge: 2147483647,
    })
    setCookie('guestId', uuidv4().replace(/-/g, ''), {
      maxAge: 2147483647,
    })
    setCookie('area-code', DEFAULT_REGION, {
      maxAge: 2147483647,
    })
    setCookie('language', Languages.VN, {
      maxAge: 2147483647,
    })
    setCookie(
      LocalStorageKeys.VERSION,
      process.env.NEXT_PUBLIC_LOCAL_STORAGE_VERSION,
      {
        maxAge: 2147483647,
      }
    )
  }
  const token = getCookie('token')
  const language = getCookie('language')
  const area = getCookie('area-code')
  const guestId = getCookie('guestId')
  const channel = getCookie('channel')
  const accessToken = getCookie('access')
  const destinationCode = getCookie('destinationCode')
  config.headers['Platform'] = Platform.DESKTOP
  config.headers['Channel'] = SaleChannel.B2C
  config.headers['Party'] = 'ecom'
  ;(config as AxiosMetadaInterface).metadata = { startTime: new Date() }

  // Client
  if (config.headers && !isServer) {
    config.headers['Accept-Language'] = language || Languages.VN
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (area) config.headers['area-code'] = area || DEFAULT_REGION
    if (guestId) config.headers['Uuid'] = guestId
    if (channel) config.headers['Channel'] = channel
    if (accessToken) config.headers['Access'] = accessToken
    if (destinationCode) config.headers['destination-code'] = destinationCode
    return config
  }

  // Server
  if (context) {
    const { token, language, channel, destinationCode } = context
    const areaCode = context['area-code']
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (language) config.headers['Accept-language'] = language
    if (channel) config.headers['Channel'] = channel
    if (guestId) config.headers['Uuid'] = guestId
    if (accessToken) config.headers['Access'] = accessToken
    if (destinationCode) config.headers['destination-code'] = destinationCode
    config.headers['area-code'] = areaCode || DEFAULT_REGION
  }
  return config
}

async function onFailedRequest(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error)
}
function onSuccessfulResponse(res: AxiosResponse<any>): AxiosResponse<any> {
  return res
}

async function onFailedResponse(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error)
}

export const applyInterceptors = (instance: AxiosInstance): void => {
  instance.interceptors.request.use(onSuccessfulRequest, onFailedRequest)
  instance.interceptors.response.use(onSuccessfulResponse, onFailedResponse)
}
