import { Area, MenuTypes, PromotionTypes } from '@/constants'
import type {
  BannerList,
  Category,
  CategoryFeature,
  CategoryListResponse,
  FlashSale,
  KeywordsResponse,
  Menu,
  Path,
  Post,
  ProductDetailResponse,
  ProductListResponse,
  ProductReviewResponse,
  Promotion,
  PromotionDetailResponse,
  PromotionProductCarousel,
  Province,
  QueryParam,
  ReasonCancel,
  Store,
  SystemConfig,
  UserAddress,
} from '@/types'
import sortBy from 'lodash/sortBy'
import { api } from './api'
import { convertQueryParam } from './next-util'
import {
  toBanner,
  toBestSeller,
  toCategoryFeatures,
  toFlashSale,
  toFlashSales,
  toHomepageProductCarousel,
  toProductTrending,
  toPromotions,
  toYourPromotions,
} from './transformer'

// type RequestParams = {
//   [key: string]: string | undefined
// }

export const getPromotions = async (): Promise<Promotion | null> => {
  const res = await api(`api/promotions?sortType=asc&page=1&limit=20`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toPromotions(res.data)
  }
  return null
}

export const getProductTrending = async (): Promise<Promotion | null> => {
  const res = await api(`api/promotions?&page=1&limit=20&type=trending`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toProductTrending(res.data)
  }
  return null
}

export const getBestSeller = async (): Promise<Promotion | null> => {
  const res = await api(
    `api/promotions?&page=1&limit=20&type=${PromotionTypes.BEST_SELLER}`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toBestSeller(res.data)
  }
  return null
}

export const getBestSellerSSR = async (): Promise<Promotion | null> => {
  const res = await api(
    `api/promotions?&page=1&limit=20&type=${PromotionTypes.BEST_SELLER}`,
    {
      baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toBestSeller(res.data)
  }
  return null
}
export const getHomepageProductCarousel = async (): Promise<
  PromotionProductCarousel[] | null
> => {
  const res = await api(
    `api/promotions?&page=1&limit=40&type=${PromotionTypes.LIST_HOT}`,
    {
      baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
      method: 'get',
    }
  )

  if (res && !(res instanceof Error)) {
    return toHomepageProductCarousel(res.data)
  }
  return null
}

export const getFlashSale = async (): Promise<FlashSale | null> => {
  const res = await api(`api/flash-sales?page=1&limit=20`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toFlashSales(res.data)
  }
  return null
}

export const getFlashSaleSSR = async (): Promise<FlashSale | null> => {
  const res = await api(`api/flash-sales?page=1&limit=20`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toFlashSales(res.data)
  }
  return null
}

export const getPosts = async (): Promise<Post[] | null> => {
  const res = await api(`api/posts?page=1&limit=20&type=news`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}

export const getPostsSSR = async (): Promise<Post[] | null> => {
  const res = await api(`api/posts?page=1&limit=20&type=news`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}

export const getAllPosts = async (): Promise<Post[] | null> => {
  const res = await api(`api/posts`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.data
  }
  return []
}

export const getAllPostsSSR = async (): Promise<Post[] | null> => {
  const res = await api(`api/posts`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.data
  }
  return []
}
export const getStores = async (): Promise<Store[] | null> => {
  const res = await api(`api/store`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}
export const getStoresSSR = async (): Promise<Store[] | null> => {
  const res = await api(`api/store`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}

export const getProvinces = async (): Promise<Province[] | null> => {
  const res = await api(`api/area?level=${Area.PROVINCE}`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Province) => ({
      ...e,
      ...{ name: e.name.replace('Tỉnh ', '').replace('Thành phố ', '') },
    }))
  }
  return []
}
export const getProvincesSSR = async (): Promise<Province[] | null> => {
  const res = await api(`api/area?level=${Area.PROVINCE}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Province) => ({
      ...e,
      ...{ name: e.name.replace('Tỉnh ', '').replace('Thành phố ', '') },
    }))
  }
  return []
}
export const getSaleRegion = async (): Promise<Province[] | null> => {
  const res = await api(`api/sale-region/province`, {
    baseURL: process.env.NEXT_PRIVATE_ECOMMERCE_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Province) => ({
      ...e,
      ...{ name: e.name.replace('Tỉnh ', '').replace('Thành phố ', '') },
    }))
  }
  return []
}

export const getMenus = async (): Promise<Menu[] | null> => {
  const res = await api(
    `api/menu-item?menuTypes=${MenuTypes.GLOBAL_TOP}&sortBy=order&sortType=asc`,
    {
      baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order').map((e: Menu) => {
      e.children = sortBy(e.children, 'order')
      return e
    })
  }
  return []
}

export const getQuickMenu = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.GLOBAL_QUICK}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}
export const getHomeMenu = async (): Promise<Menu[] | null> => {
  const res = await api(
    `api/menu-item?menuTypes=${MenuTypes.HOME_PRODUCT_CAT}`,
    {
      baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}

export const getBottomMenu = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.GLOBAL_BOTTOM}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}

export const getCategoriesFeature = async (): Promise<
  CategoryFeature[] | null
> => {
  const res = await api(`api/product-cats?page=1&limit=20featured=true`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toCategoryFeatures(res.data)
  }
  return []
}
export const getCategoriesFeatureSSR = async (): Promise<
  CategoryFeature[] | null
> => {
  const res = await api(`api/product-cats?page=1&limit=20featured=true`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toCategoryFeatures(res.data)
  }
  return []
}

export const getBanner = async (): Promise<BannerList[] | null> => {
  const res = await api(`api/banners`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toBanner(res.data)
  }
  return []
}

export const getBannerSSR = async (): Promise<BannerList[] | null> => {
  const res = await api(`api/banners`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toBanner(res.data)
  }
  return []
}

export const getCategory = async (
  id: string | string[],
  queryParam?: QueryParam
): Promise<Category | null> => {
  const params = convertQueryParam(queryParam)
  const res = await api(`api/product-cat/${id}/detail?${params}&limit=40`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    if (res.data?.properties) {
      res.data.properties = sortBy(res.data.properties, 'order')
    }
    return res.data
  }
  return null
}

export const getCategorySSR = async (
  id: string | string[],
  queryParam?: QueryParam
): Promise<Category | null> => {
  const params = convertQueryParam(queryParam)
  const res = await api(`api/product-cat/${id}/detail?${params}&limit=40`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    if (res.data?.properties) {
      res.data.properties = sortBy(res.data.properties, 'order')
    }
    return res.data
  }
  return null
}
export const getAllCategory = async (): Promise<Category[] | null> => {
  const res = await api(`api/product-cat`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    if (res.data?.order) {
      res.data.order = sortBy(res.data.order, 'order')
    }
    return res.data
  }
  return null
}
export const getAllCategorySSR = async (): Promise<Category[] | null> => {
  const res = await api(`api/product-cat`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    if (res.data?.order) {
      res.data.order = sortBy(res.data.order, 'order')
    }
    return res.data
  }
  return null
}
export const getProductWithPaginate = async (
  queryParams: QueryParam
): Promise<ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/products?${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getProductWithPaginateSSR = async (
  queryParams: QueryParam
): Promise<ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/products?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getYourPromotions = async (
  queryParams?: QueryParam
): Promise<Promotion[] | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(
    `api/promotions?sortBy=order&sortType=asc&page=1&limit=20&type=${PromotionTypes.YOUR_PROMOTION}&${params}`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toYourPromotions(res.data)
  }
  return null
}
export const getYourPromotionsSRR = async (
  queryParams?: QueryParam
): Promise<Promotion[] | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(
    `api/promotions?sortBy=order&sortType=asc&page=1&limit=20&type=${PromotionTypes.YOUR_PROMOTION}&${params}`,
    {
      baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toYourPromotions(res.data)
  }
  return null
}

export const getProductDetail = async (
  id: string,
  queryParams?: QueryParam
): Promise<ProductDetailResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/product/${id}/detail?${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res
  }

  return null
}
export const getProductDetailSSR = async (
  id: string,
  queryParams?: QueryParam
): Promise<ProductDetailResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/product/${id}/detail?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res
  }

  return null
}

export const getPageDetail = async (
  path: string[],
  queryParams?: QueryParam
): Promise<ProductDetailResponse | ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  if (path.length > 1) {
    if (path[1].indexOf('c') > -1) {
      const res = await api(`api/products?${params}`, {
        baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res.data
      }
    }
    if (path[1].indexOf('p') > -1) {
      const res = await api(`api/post/${path[0]}`, {
        baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res
      }
    }
    if (path[1].indexOf('b') > -1) {
      const res = await api(`api/post/${path[0]}`, {
        baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res
      }
    }
    return null
  }

  const res = await api(`api/product/${path[0]}/detail?${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res
  }

  return null
}
export const getPageDetailSSR = async (
  path: string[],
  queryParams?: QueryParam
): Promise<ProductDetailResponse | ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  if (path.length > 1) {
    if (path[1].indexOf('c') > -1) {
      const res = await api(`api/products?${params}`, {
        baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res.data
      }
    }
    if (path[1].indexOf('p') > -1) {
      const res = await api(`api/post/${path[0]}`, {
        baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res
      }
    }
    if (path[1].indexOf('b') > -1) {
      const res = await api(`api/post/${path[0]}`, {
        baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res
      }
    }
    return null
  }

  const res = await api(`api/product/${path[0]}/detail?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res
  }

  return null
}

export const getPath = async (path: string): Promise<Path | null> => {
  const res = await api(`api/path-mapping/parsing?path=${path}`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getPathSSR = async (path: string): Promise<Path | null> => {
  const res = await api(`api/path-mapping/parsing?path=${path}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getCategories = async (
  queryParams?: QueryParam
): Promise<CategoryListResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/product-cats?${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getCategoriesSSR = async (
  queryParams?: QueryParam
): Promise<CategoryListResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/product-cats?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getConfig = async (): Promise<SystemConfig[] | null> => {
  const res = await api(`api/configuration`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}
export const getConfigSSR = async (): Promise<SystemConfig[] | null> => {
  const res = await api(`api/configuration`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}

export const getPromotionDetail = async (
  id?: string
): Promise<PromotionDetailResponse | null> => {
  const res = await api(`api/promotion/${id}`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })

  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}
export const getPromotionDetailSSR = async (
  id: string
): Promise<PromotionDetailResponse | null> => {
  const res = await api(`api/promotion/${id}`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}

export const getPostDetail = async (
  slug: string
): Promise<PromotionDetailResponse | null> => {
  const res = await api(`api/post/${slug}`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}
export const getPostDetailSSR = async (
  slug: string
): Promise<PromotionDetailResponse | null> => {
  const res = await api(`api/post/${slug}`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}

export const getShippingAddress = async (): Promise<UserAddress[] | null> => {
  const res = await api(`api/user-contact?type=shipping`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })

  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getShippingAddressDetail = async (
  params?: string
): Promise<UserAddress | null> => {
  const res = await api(`api/user-contact?ids=${params}&type=shipping`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })

  if (res && !(res instanceof Error)) {
    return res.data[0]
  }
  return null
}

export const getReasonCancel = async (): Promise<ReasonCancel[] | null> => {
  const res = await api(`api/order/cancelled-order-reason?display=true`, {
    method: 'get',
    baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
  })

  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getProductReviews = async (
  params?: string
): Promise<ProductReviewResponse | null> => {
  const res = await api(`api/reviews?page=1&limit=5&${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}

export const getAllProductReview = async (
  params?: string
): Promise<ProductReviewResponse | null> => {
  const res = await api(`api/reviews?page=1&limit=50&${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}

export const getFlashSaleDetail = async (
  id: string
): Promise<FlashSale | null> => {
  const res = await api(`api/flash-sale/${id}`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toFlashSale(res.data as FlashSale)
  }
  return null
}
export const getKeyword = async (): Promise<KeywordsResponse | null> => {
  const res = await api(
    `api/keywords?type=footerKeyword&sortBy=order&sortType=asc&page=1&limit=40`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return res.data.data
  }
  return null
}
